import { memo } from 'react';
import type { Thing } from 'schema-dts';

interface JsonLdSchemaProps {
    content: Thing;
}

export const JsonLdSchema = memo(({ content }: JsonLdSchemaProps) => {
    return (
        <script
            type="application/ld+json"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: We trust the JSON.stringify output
            dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                    '@context': 'https://schema.org',
                    ...(typeof content === 'string' ? {} : content),
                }),
            }}
        />
    );
});
