import { ContentImageVariantType } from '../enums';
import type { ContentMobileImageSet } from '../graphql/_generated-graphql-types';
import type { ContentImage, ContentImageSet } from '../interfaces';

export const getImageByVariant = (
    images?: ContentImageSet[] | ContentMobileImageSet[],
    variant?: ContentImageVariantType,
): ContentImageSet | undefined => {
    if (!images?.length) {
        return undefined;
    }

    const image: ContentImageSet | ContentMobileImageSet | undefined = variant
        ? // @ts-ignore Weird error about this expression is not callable in repo.
          images.find(
              (image: ContentImageSet | ContentMobileImageSet) =>
                  image.variant === variant,
          )
        : images[0];

    if (!image) {
        return undefined;
    }

    // if image url exist we know we have the ContentImage type already
    if ((image as ContentImageSet).url) {
        return image as ContentImageSet;
    }

    // else we have to normalize it to ContentImage
    // todo: check if this logic can be easily moved as normalisation in GQL without bothering Mobile
    const {
        link,
        variant: imageVariant,
        width,
        height,
    } = image as ContentMobileImageSet;

    return {
        description: '',
        url: link,
        width: Number(width),
        height: Number(height),
        variant: imageVariant,
    };
};

export const getImageVariant = (
    images: ContentImageSet[] | ContentMobileImageSet[],
    variant: ContentImageVariantType,
): string | null => {
    if (!images?.length) {
        return null;
    }

    let image = getImageByVariant(images, variant);

    if (variant === ContentImageVariantType.XL && !image) {
        image = getImageByVariant(images, ContentImageVariantType.LARGE);
    }

    if (!image) {
        image = getImageByVariant(images);
    }

    return image?.url || null;
};

export const getAllImageVariants = (imageSet: ContentImage) => {
    const small = getImageByVariant(
        imageSet?.variants,
        ContentImageVariantType.SMALL,
    );
    const medium = getImageByVariant(
        imageSet?.variants,
        ContentImageVariantType.MEDIUM,
    );
    const large = getImageByVariant(
        imageSet?.variants,
        ContentImageVariantType.LARGE,
    );
    const full = getImageByVariant(
        imageSet?.variants,
        ContentImageVariantType.FULL,
    );
    const xl = getImageByVariant(
        imageSet?.variants,
        ContentImageVariantType.XL,
    );

    return {
        [(ContentImageVariantType.SMALL as string).toLowerCase()]: small,
        [(ContentImageVariantType.MEDIUM as string).toLowerCase()]: medium,
        [(ContentImageVariantType.LARGE as string).toLowerCase()]: large,
        [(ContentImageVariantType.XL as string).toLowerCase()]: xl,
        [(ContentImageVariantType.FULL as string).toLowerCase()]: full,
    };
};
