import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    RECIPE_SERVINGS,
    RECIPE_SERVINGS_AMOUNT,
    RECIPE_SERVINGS_MINUS,
    RECIPE_SERVINGS_PLUS,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { Minus24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/minus-24';
import { Plus24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/plus-24';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classnames from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import type { RecipeDetailsServing } from '../../../graphql/_generated-graphql-types';
import { getServingsTranslations } from './helpers/translations';
import { useServingsInput } from './servings-input-hook';

import css from './servings-input.module.scss';

export interface ServingsInputProps extends PropsWithChildren {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    servings: RecipeDetailsServing;
    onChange: (amount: number) => void;
    formVariant?: boolean;
    className?: string;
}

export const ServingsInput: FC<ServingsInputProps> = ({
    locale = DEFAULT_LOCALE,
    servings,
    onChange,
    formVariant,
    className,
    ...restProps
}) => {
    const { min, max, scale, isChangeable, type, number } = servings;

    const { t } = getServingsTranslations(locale);

    const { increment, decrement } = useServingsInput({
        number,
        scale,
        min,
        max,
        onChange,
    });

    return (
        <div
            className={classnames(
                css.container,
                {
                    [css.containerFormVariant]: formVariant,
                },
                className,
            )}
            {...restProps}
            {...createTestHook(RECIPE_SERVINGS)}
        >
            <Typography
                id="recipe-servings"
                variant={formVariant ? 'body-strong' : 'body-regular'}
            >
                {type === 'personen' ? t('type.persons') : t('type.portions')}
            </Typography>

            <div className={css.iconsContainer}>
                {isChangeable && scale > 0 && (
                    <button
                        disabled={!decrement.isEnabled}
                        className={css.iconButton}
                        onClick={decrement.handleClick}
                        type="button"
                        aria-label={t('decrease')}
                        {...createTestHook(RECIPE_SERVINGS_MINUS)}
                    >
                        <Minus24Icon
                            aria-hidden="true"
                            className={css.icon}
                            size={14}
                        />
                    </button>
                )}

                <Typography
                    variant="body-strong"
                    className={classnames(isChangeable && css.counter)}
                    aria-live="polite"
                    aria-label={`${type === 'persons' ? t('type.persons') : t('type.portions')}: ${number}`}
                >
                    <span
                        aria-hidden
                        {...createTestHook(RECIPE_SERVINGS_AMOUNT)}
                    >
                        {number}
                    </span>
                </Typography>

                {isChangeable && scale > 0 && (
                    <button
                        disabled={!increment.isEnabled}
                        className={css.iconButton}
                        onClick={increment.handleClick}
                        type="button"
                        aria-label={t('increase')}
                        {...createTestHook(RECIPE_SERVINGS_PLUS)}
                    >
                        <Plus24Icon
                            aria-hidden="true"
                            className={css.icon}
                            size={14}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};
