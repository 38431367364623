import { useEffect, useState } from 'react';

export const useHostUrl = (): string => {
    const [host, setHost] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined' && host === '') {
            setHost(`${window.location.protocol}//${window.location.host}`);
        }
    });

    return host;
};
