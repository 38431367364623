import type { ApolloError } from '@apollo/client/errors';
import {
    RecipeCollectionCategoriesDocument,
    useRecipeCollectionRemoveRecipeFromCategoriesMutation,
} from '../../_generated-hooks';
import {
    type CollectionCategoryRecipeType,
    MutationResultStatus,
    type RecipeCollectionCategoriesQuery,
} from '../../_generated-operation-types';

const ALLERHANDE_FAV_PATH = '/allerhande/favorieten';
const ALLERHANDE_MY_RECIPE_PATH = '/allerhande/mijn-recept';

export interface UseRemoveRecipeFromCategories {
    loading: boolean;
    error?: ApolloError;
    removeRecipeFromCategories: (
        recipeId: number,
        type: CollectionCategoryRecipeType,
        onSaveListCompleted?: (hasError: boolean) => void,
    ) => void;
}

export const useRemoveRecipeFromCategories =
    (): UseRemoveRecipeFromCategories => {
        const [removeRecipeFromCategoriesMutation, { loading, error }] =
            useRecipeCollectionRemoveRecipeFromCategoriesMutation({
                fetchPolicy: 'network-only',
            });

        const removeRecipeFromCategories = (
            recipeId: number,
            type: CollectionCategoryRecipeType,
            onSaveListCompleted?: (hasError: boolean) => void,
        ): void => {
            removeRecipeFromCategoriesMutation({
                variables: { recipeId, collectedRecipeType: type },
                onCompleted: () => {
                    onSaveListCompleted && onSaveListCompleted(false);
                    if (
                        window.location.pathname.includes(
                            ALLERHANDE_MY_RECIPE_PATH,
                        )
                    ) {
                        window.location.href = ALLERHANDE_FAV_PATH;
                    }
                },
                onError: () => onSaveListCompleted && onSaveListCompleted(true),
                update: (store, { errors, data }) => {
                    if (
                        !errors &&
                        data?.recipeCollectionRemoveRecipeFromCategoriesV3
                            .status === MutationResultStatus.SUCCESS
                    ) {
                        store.updateQuery<RecipeCollectionCategoriesQuery>(
                            {
                                query: RecipeCollectionCategoriesDocument,
                            },
                            data => ({
                                recipeCollectionCategories:
                                    data?.recipeCollectionCategories.map(
                                        category => ({
                                            ...category,
                                            list: category.list.filter(
                                                ({ id }) => id !== recipeId,
                                            ),
                                        }),
                                    ) || [],
                            }),
                        );
                    }
                },
            }).catch();
        };

        return {
            removeRecipeFromCategories,
            loading,
            error,
        };
    };
