import { createImageSrcSet as cmsCreateImageSrcSet } from '@royalaholddelhaize/ah-ui-cms/src/helpers/create-image-src-set';
import type {
    ContentImage,
    ContentImageSet,
} from '@royalaholddelhaize/ah-ui-cms/src/interfaces/image-set.interface';
import { generateRecipeImageRenditions } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/utils/image-renditions';
import type { RecipeImage } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-graphql-types';

export const createImageSrcSet = (
    images: (Omit<RecipeImage, 'rendition'> | ContentImageSet)[] | ContentImage,
) => {
    if ('variants' in images) {
        return cmsCreateImageSrcSet(images);
    }

    if (images.length && 'variant' in images[0]) {
        return cmsCreateImageSrcSet({ variants: images } as ContentImage);
    }

    return generateRecipeImageRenditions(
        images as Omit<RecipeImage, 'rendition'>[],
    );
};
