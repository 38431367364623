import { useHostUrl } from '@helpers/client/host';
import type { Breadcrumb } from '@interfaces/breadcrumb';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import type { BreadcrumbList } from 'schema-dts';
import { JsonLdSchema } from './json-ld-schema';

interface BreadcrumbsSchemaProps {
    breadcrumbs?: Breadcrumb[];
}

export const BreadcrumbsSchema: FC<BreadcrumbsSchemaProps> = ({
    breadcrumbs = [],
}) => {
    const pathname = usePathname();
    const host = useHostUrl();
    const pageUrl = `${host}${pathname}`;
    const links = [
        {
            href: '',
            name: 'Albert Heijn',
        },
        ...breadcrumbs,
    ];

    const json: BreadcrumbList = {
        '@type': 'BreadcrumbList',
        '@id': `${pageUrl}#breadcrumbs`,
        itemListElement: links.map(({ href, name }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': `${host}${href}`,
                name,
            },
        })),
    };

    return <JsonLdSchema content={json} />;
};
