import type { MouseEvent } from 'react';

export interface UseServingsInputHook {
    increment: {
        isEnabled: boolean;
        handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
    };
    decrement: {
        isEnabled: boolean;
        handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
    };
}

interface UseServingsInputHookParams {
    scale: number;
    min: number;
    max: number;
    number: number;
    onChange: (amount: number) => void;
}

export const useServingsInput = ({
    scale,
    min,
    max,
    number,
    onChange,
}: UseServingsInputHookParams): UseServingsInputHook => {
    const isDecrementEnabled = number - scale >= min;
    const isIncrementEnabled = number + scale <= max;

    const handleDecrementClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (isDecrementEnabled) {
            onChange(number - scale);
        }
    };

    const handleIncrementClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (isIncrementEnabled) {
            onChange(number + scale);
        }
    };

    return {
        increment: {
            isEnabled: isIncrementEnabled,
            handleClick: handleIncrementClick,
        },
        decrement: {
            isEnabled: isDecrementEnabled,
            handleClick: handleDecrementClick,
        },
    };
};
