'use client';

import AllerhandeHomeIcon from '@assets/icons/home.svg';
import { BreadcrumbsSchema } from '@components/elements/json-ld/breadcrumbs-schema';
import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import type { Breadcrumb } from '@interfaces/breadcrumb';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    BREADCRUMBS,
    BREADCRUMBS_BACK,
    BREADCRUMBS_ITEM,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-24';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { useRouter } from 'next/navigation';
import type { FC, PropsWithChildren } from 'react';

import css from './breadcrumbs.module.scss';

export type BreadcrumbProps = {
    breadcrumbs?: Breadcrumb[];
    withoutBorder?: boolean;
};

export const Breadcrumbs: FC<PropsWithChildren<BreadcrumbProps>> = ({
    breadcrumbs = [],
    withoutBorder = false,
}) => {
    const { t } = useTranslations('navigation');
    const completeBreadcrumbs: Breadcrumb[] = [
        { href: AllerhandeRoute.Home, name: 'Allerhande' },
        ...breadcrumbs,
    ];
    const router = useRouter();

    const handleOnBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            router.back();
        } else {
            router.push(
                completeBreadcrumbs[completeBreadcrumbs.length - 1].href,
            );
        }
    };

    return (
        <div
            className={classNames(css.root, withoutBorder && css.noBorder)}
            {...createTestHook(BREADCRUMBS)}
        >
            <button
                className={css.back}
                title={t('back')}
                aria-label={t('back')}
                onClick={handleOnBack}
                {...createTestHook(BREADCRUMBS_BACK)}
            >
                <ChevronLeft24Icon className={css.backIcon} size="20" />

                <Typography
                    className={css.backLabel}
                    variant="body-regular"
                    as="span"
                >
                    {t('back')}
                </Typography>
            </button>

            <ul className={css.crumbs}>
                <li className={classNames(css.crumb, css.home)}>
                    <LinkOrAnchor
                        href="/"
                        title="startpagina"
                        aria-label="startpagina"
                    >
                        <AllerhandeHomeIcon height={24} width={24} />
                    </LinkOrAnchor>
                </li>

                {completeBreadcrumbs.map(breadcrumb => {
                    const { name, href } = breadcrumb;
                    return (
                        <li className={css.crumb} key={name}>
                            <LinkOrAnchor
                                href={href}
                                className={css.link}
                                title={name}
                                aria-label={name}
                                {...createTestHook(BREADCRUMBS_ITEM)}
                            >
                                <Typography
                                    className={css.linkLabel}
                                    variant="body-regular"
                                    as="span"
                                >
                                    {name}
                                </Typography>
                            </LinkOrAnchor>
                        </li>
                    );
                })}
                <BreadcrumbsSchema breadcrumbs={completeBreadcrumbs} />
            </ul>
        </div>
    );
};
