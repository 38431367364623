import type { ContentImage } from '../interfaces';
import { getAllImageVariants } from './get-image-variant.helper';

const getCorrectImageSet = (imageSet: ContentImage) => {
    const { small, medium, large, xl, full } = getAllImageVariants(imageSet);
    return { small, medium, large, xl, full };
};

const printDimension = (
    dimension: number | undefined | null,
    unit: string,
): string => {
    return dimension ? `${dimension}${unit}` : '';
};

const generateGenericSrcSet = (imageSet: ContentImage): string | undefined => {
    if (!Array.isArray(imageSet.variants) || imageSet.variants.length === 0) {
        return;
    }
    return imageSet.variants
        .filter(Boolean)
        .map(({ url, width, height }) => {
            return url
                ? `${url} ${printDimension(width, 'w')} ${printDimension(height, 'h')}`
                : '';
        })
        .join(', ');
};

export const createImageSrcSet = (
    imageSet: ContentImage,
): string | undefined => {
    const { small, medium, large, xl, full } = getCorrectImageSet(imageSet);
    const key = 'url';

    let srcSet = small ? `${small?.[key]} ${small?.width}w,` : '';

    if (medium?.[key]) {
        srcSet = `${srcSet} ${medium?.[key]} ${medium?.width}w,`;
    }
    if (large?.[key]) {
        srcSet = `${srcSet} ${large?.[key]} ${large?.width}w,`;
    }
    if (xl?.[key]) {
        srcSet = `${srcSet} ${xl?.[key]} ${xl?.width}w,`;
    }
    if (full?.[key]) {
        srcSet = `${srcSet} ${full?.[key]} ${full?.width}w,`;
    }

    return srcSet ? srcSet : generateGenericSrcSet(imageSet);
};
